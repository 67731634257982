@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-RegularItalic.eot');
    src: local('Reckless Neue Regular Italic'), local('RecklessNeue-RegularItalic'),
        url('RecklessNeue-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-RegularItalic.woff2') format('woff2'),
        url('RecklessNeue-RegularItalic.woff') format('woff'),
        url('RecklessNeue-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-ThinItalic.eot');
    src: local('Reckless Neue Thin Italic'), local('RecklessNeue-ThinItalic'),
        url('RecklessNeue-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-ThinItalic.woff2') format('woff2'),
        url('RecklessNeue-ThinItalic.woff') format('woff'),
        url('RecklessNeue-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue Book';
    src: url('RecklessNeue-Book.eot');
    src: local('Reckless Neue Book'), local('RecklessNeue-Book'),
        url('RecklessNeue-Book.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Book.woff2') format('woff2'),
        url('RecklessNeue-Book.woff') format('woff'),
        url('RecklessNeue-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Regular.eot');
    src: local('Reckless Neue Regular'), local('RecklessNeue-Regular'),
        url('RecklessNeue-Regular.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Regular.woff2') format('woff2'),
        url('RecklessNeue-Regular.woff') format('woff'),
        url('RecklessNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Light.eot');
    src: local('Reckless Neue Light'), local('RecklessNeue-Light'),
        url('RecklessNeue-Light.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Light.woff2') format('woff2'),
        url('RecklessNeue-Light.woff') format('woff'),
        url('RecklessNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Thin.eot');
    src: local('Reckless Neue Thin'), local('RecklessNeue-Thin'),
        url('RecklessNeue-Thin.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Thin.woff2') format('woff2'),
        url('RecklessNeue-Thin.woff') format('woff'),
        url('RecklessNeue-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Heavy.eot');
    src: local('Reckless Neue Heavy'), local('RecklessNeue-Heavy'),
        url('RecklessNeue-Heavy.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Heavy.woff2') format('woff2'),
        url('RecklessNeue-Heavy.woff') format('woff'),
        url('RecklessNeue-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-LightItalic.eot');
    src: local('Reckless Neue Light Italic'), local('RecklessNeue-LightItalic'),
        url('RecklessNeue-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-LightItalic.woff2') format('woff2'),
        url('RecklessNeue-LightItalic.woff') format('woff'),
        url('RecklessNeue-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-SemiBoldItalic.eot');
    src: local('Reckless Neue SemiBold Italic'), local('RecklessNeue-SemiBoldItalic'),
        url('RecklessNeue-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-SemiBoldItalic.woff2') format('woff2'),
        url('RecklessNeue-SemiBoldItalic.woff') format('woff'),
        url('RecklessNeue-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Bold.eot');
    src: local('Reckless Neue Bold'), local('RecklessNeue-Bold'),
        url('RecklessNeue-Bold.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Bold.woff2') format('woff2'),
        url('RecklessNeue-Bold.woff') format('woff'),
        url('RecklessNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-Medium.eot');
    src: local('Reckless Neue Medium'), local('RecklessNeue-Medium'),
        url('RecklessNeue-Medium.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-Medium.woff2') format('woff2'),
        url('RecklessNeue-Medium.woff') format('woff'),
        url('RecklessNeue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-BoldItalic.eot');
    src: local('Reckless Neue Bold Italic'), local('RecklessNeue-BoldItalic'),
        url('RecklessNeue-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-BoldItalic.woff2') format('woff2'),
        url('RecklessNeue-BoldItalic.woff') format('woff'),
        url('RecklessNeue-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-MediumItalic.eot');
    src: local('Reckless Neue Medium Italic'), local('RecklessNeue-MediumItalic'),
        url('RecklessNeue-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-MediumItalic.woff2') format('woff2'),
        url('RecklessNeue-MediumItalic.woff') format('woff'),
        url('RecklessNeue-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-HeavyItalic.eot');
    src: local('Reckless Neue Heavy Italic'), local('RecklessNeue-HeavyItalic'),
        url('RecklessNeue-HeavyItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-HeavyItalic.woff2') format('woff2'),
        url('RecklessNeue-HeavyItalic.woff') format('woff'),
        url('RecklessNeue-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue Book';
    src: url('RecklessNeue-BookItalic.eot');
    src: local('Reckless Neue Book Italic'), local('RecklessNeue-BookItalic'),
        url('RecklessNeue-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-BookItalic.woff2') format('woff2'),
        url('RecklessNeue-BookItalic.woff') format('woff'),
        url('RecklessNeue-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Reckless Neue';
    src: url('RecklessNeue-SemiBold.eot');
    src: local('Reckless Neue SemiBold'), local('RecklessNeue-SemiBold'),
        url('RecklessNeue-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('RecklessNeue-SemiBold.woff2') format('woff2'),
        url('RecklessNeue-SemiBold.woff') format('woff'),
        url('RecklessNeue-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

